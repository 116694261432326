<!-- 合同购买确认框 -->

<template>
    <div class="dialog">
        <el-dialog :visible.sync="dialogVisible" width="30%" title="订单支付" @close="close" append-to-body>
            <div class="dialog__toptitle">
                <div class="dialog__toptitle_l">实付金额</div>
                <div class="dialog__toptitle_r red">
                    <div class="icon">¥</div> {{ yuezhifujia | priceFormat }}
                </div>
            </div>
            <div class="dialog__item">
                <div class="dialog__item__l grey">发起合同：</div>
                <div class="dialog__item__r">¥ {{ price | priceFormat }}</div>
            </div>
            <div class="dialog__item" v-show="hetongbaoprice">
                <div class="dialog__item__l grey">合同保<span>（{{ hetongbaotitle }}）</span>：</div>
                <div class="dialog__item__r">¥ {{ hetongbaoprice | priceFormat }}</div>
            </div>
            <div class="dialog__item">
                <div class="dialog__item__l grey">我的代金券：</div>
                <div class="dialog__item__r" @click="showvoucher" v-if="VoucherList.length">
                    <span v-if="youhuiquanjiage > 0" class="red">-¥ {{ youhuiquanjiage }}</span>
                    <span v-else>{{ daijinquanrender }}</span>


                    <svg xmlns="http://www.w3.org/2000/svg" width="5" height="8" viewBox="0 0 5 8" fill="none">
                        <path
                            d="M0.685714 -2.99735e-08L6.42392e-09 0.666667L3.42857 4L-2.91409e-08 7.33333L0.685714 8L4.8 4L0.685714 -2.99735e-08Z"
                            fill="#CCCCCC" />
                    </svg>

                </div>
                <div class="dialog__item__r" v-else>暂无代金券</div>
            </div>
            <!-- <div class="dialog__item">
                <div class="dialog__item__l">总金额：</div>
                <div class="dialog__item__r blue">¥ {{ zongjia | priceFormat }}</div>
            </div> -->
            <div class="dialog__title" style="margin-top: 20px;">支付方式</div>
            <paymentMethod v-model="paymentMethod" :isbalance="isbalance" />

            <!-- <div class="dialog__item">
                <div class="dialog__item__l">余额支付(¥{{ balance | priceFormat }})：</div>
                <div class="dialog__item__r red">-¥ {{ yuezhifujia | priceFormat }}</div>
            </div> -->
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="close">取 消</el-button>
                <el-button type="primary" @click="submit" size="mini">确 定</el-button>
            </span>
        </el-dialog>


        <voucher ref="voucher" @success="vouchersuccess" :VoucherList="VoucherList" />
    </div>

</template>

<script>
import voucher from './voucher.vue'
import paymentMethod from "@/components/paymentMethod/paymentMethod"
export default {
    components: {
        voucher,
        paymentMethod
    },
    props: {
        getSubmit: {
            type: Function,
            default: () => ({})
        }
    },
    data() {
        return {
            dialogVisible: false,
            price: 5,
            VoucherList: [],
            voucherId: null,
            paymentMethod: null
        }
    },
    computed: {
        //  余额
        balance() {
            return this.$store.state.user.balance
        },
        // 合同保标题
        hetongbaotitle() {
            return this.$store.state.insuranceSelect.title
        },
        // 合同保价格
        hetongbaoprice() {
            return this.$store.state.insuranceSelect.price
        },
        // 总价
        zongjia() {
            return this.price + this.hetongbaoprice
        },
        // 优惠券抵扣价格
        youhuiquanjiage() {
            if (!this.voucherId) {
                return 0
            }
            return this.voucherItem.amount
        },
        daijinquanrender() {
            if (this.youhuiquanjiage > 0) {
                return this.youhuiquanjiage
            }
            return "请选择代金券"
        },
        // 余额支付多少钱
        yuezhifujia() {
            const jiage = this.zongjia - this.youhuiquanjiage
            if (jiage > 0) {
                return jiage
            }
            return 0
        },
        // 余额是否够  
        isbalance() {
            return !(this.balance < this.yuezhifujia)

        },
        voucherItem() {
            if (!this.voucherId) {
                return null
            }
            return this.VoucherList.find(res => res.voucherId == this.voucherId)
        }
    },
    methods: {
        showvoucher() {
            console.log(this.$refs.voucher)
            this.$refs.voucher.show()
        },
        show() {
            this.getVoucherList()
            this.dialogVisible = true;
        },
        close() {
            this.dialogVisible = false
        },

        async submit() {
            if(this.paymentMethod == 1){
                await this.$store.dispatch("user/buy", this.yuezhifujia);
            }
            this.$emit('success', { voucherId: this.voucherId, paymentMethod: this.paymentMethod, price:this.yuezhifujia})
        },
        async getVoucherList() {
            console.log('getVoucherList')
            const { status, data } = await this.$api.getVoucherList({ type: 2, state: 0 });
            if (status === 200) {
                this.VoucherList = data.records;
            }
        },
        vouchersuccess(res) {
            if (res) {
                this.voucherId = res.voucherId
            } else {
                this.voucherId = null
            }
        }
    }
}
</script>

<style lang="scss">
.blue {
    color: #0062FF;
}

.red {
    color: #FF0D0D;
}

.grey {
    color: #666
}

/deep/.el-dialog {
    background: #F3F6F8
}

.dialog__toptitle {
    display: flex;
    margin: 0 16px;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    border-bottom: 1px dashed #ccc;

    &_l {
        color: #262626;

        font-family: "Source Han Sans CN";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    &_r {
        display: flex;
        color: #FF0D0D;
        font-family: "DIN Alternate";
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        .icon {
            font-size: 16px;
            margin-top: 13px;
            margin-right: 4px;
        }
    }

}

.dialog__title {
    color: #262626;
    font-family: "Source Han Sans CN";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    background: #fff;
    padding: 0 16px;


}

.dialog__item {
    color: #262626;
    font-family: "Source Han Sans CN";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 50px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__l {}

    &__r {}
}
</style>
