import * as pdfjsLib from 'pdfjs-dist';

// 设置 Web Worker 路径
pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://meimengapp.oss-cn-beijing.aliyuncs.com/znsign/js/pdf.workeer.min.js';

async function getPDFPageSize(pdfUrl) {
  try {
    // 确保 pdfUrl 有效
    if (!pdfUrl) throw new Error('PDF 文件路径无效');

    // 加载 PDF 文档
    const loadingTask = pdfjsLib.getDocument(pdfUrl); // 可以是 URL 或 Blob
    const pdf = await loadingTask.promise;

    // 获取第一页
    const page = await pdf.getPage(1);

    // 获取页面视口
    const viewport = page.getViewport({ scale: 1 });

    // 输出尺寸
    const width = viewport.width;
    const height = viewport.height;
    console.log(`PDF 页面尺寸: 宽度 = ${width}, 高度 = ${height}`);
    return { width, height };
  } catch (error) {
    console.error('获取 PDF 页面尺寸时出错:', error);
  }
}
export default getPDFPageSize
